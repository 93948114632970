import $ from "jquery";
import Swiper from "swiper";
import Isotope from "isotope-layout";
import packery from "isotope-packery";
import imagesLoaded from "imagesloaded";
import "magnific-popup";
import initMicroModal from "./components/modal";

initMicroModal();

/**
 * Animate the viewprot to cetain offest
 * @param  {Number}   to       the offset to animate to
 * @param  {Number}   time     the time for the animation in ms
 * @param  {Function} callback callback function after the animation
 */
function animateViewportScroll(to, time, callback) {
    $("html, body").animate(
        {
            scrollTop: to,
        },
        time,
        "linear",
        callback
    );
}

$(window).on("load", function () {
    /**
     * Location Map
     */

    if ($(window).width() > 1024) {
        $(".bottle-cap").on({
            mouseenter: function (e) {
                // Get Location Parameter
                var location = $(this).attr("id").substr(4);

                // Add Blackout class to all other elements
                $(
                    "#map .locations-container path, #map .locations-container mask, #map .locations-container stroke, #map .locations-container polygon, #map .locations-container rect, #map defs rect, .bottle-cap"
                )
                    .not($("#location-" + location).find("path"))
                    .addClass("overlay");
                $(this).removeClass("overlay");

                // Show Location Counties
                $("#location-" + location).css("display", "block");
                $("#content-" + location).css("display", "block");
            },
            mouseleave: function (e) {
                // Get Location Parameter
                var location = $(this).attr("id").substr(4);

                // Remove Blackout class
                $("#map *").removeClass("overlay");

                // Hide Location Counties
                $("#location-" + location).css("display", "none");
                $("#content-" + location).css("display", "none");
            },
        });
    }

    /**
     * Hero slider init
     */
    $(".js-slider-hero").each((i, elem) => {
        const sliderHero = $(elem).find(".swiper-container")[0];

        const boxSlider = new Swiper(sliderHero, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                prevEl: $(elem).find(".swiper-button-prev"),
                nextEl: $(elem).find(".swiper-button-next"),
            },
        });
    });

    /**
     * Text slider init
     */
    $(".js-slider-text").each((i, elem) => {
        const sliderText = $(elem).find(".swiper-container")[0];

        const boxSlider = new Swiper(sliderText, {
            slidesPerView: 1,
            spaceBetween: 0,
            autoplay: {
                delay: 7500,
                disableOnInteraction: false,
            },
        });
    });

    /**
     * Brands slider init
     */
    $(".js-slider-brands").each((i, elem) => {
        const sliderBrands = $(elem).find(".swiper-container")[0];

        const boxSlider = new Swiper(sliderBrands, {
            slidesPerView: 7,
            spaceBetween: 0,
            watchSlidesVisibility: true,
            pagination: {
                el: $(elem).find(".swiper-pagination"),
                type: "progressbar",
            },
            navigation: {
                prevEl: $(elem).find(".swiper-button-prev"),
                nextEl: $(elem).find(".swiper-button-next"),
            },
        });
    });

    /**
     * Gallery images isotope init
     */
    const gallery = document.querySelector(".js-isotope-grid");

    if (gallery) {
        const iso = new Isotope(gallery, {
            layoutMode: "packery",
            itemSelector: ".isotope-item",
            percentPosition: true,
        });

        imagesLoaded(gallery, (instance) => {
            iso.layout();
            gallery.classList.add("is-loaded");
        });
    }

    /**
     * Navigation Funcitonality
     */
    $(".nav a").on("click", function (event) {
        if ($(this).siblings("ul").length) {
            if ($win.width() < 1024) {
                if (!$(this).parent().hasClass("is-expanded")) {
                    event.preventDefault();
                }
            }
            $(this)
                .parent()
                .addClass("is-expanded")
                .siblings()
                .removeClass("is-expanded");
        }
    });

    /**
     * Search Functionality
     */

    $(".search__trigger").on("click", function (event) {
        event.preventDefault();

        $(this).parent().toggleClass("is-search-active");
    });

    /**
     * Burger Button Functionality
     */
    $(".btn-menu").on("click", function (event) {
        event.preventDefault();

        $(this).toggleClass("is-active");
        $(".wrapper").toggleClass("is-nav-active");
    });

    /**
     * Values Rollovers
     */

    if (is_touch_enabled()) {
        $(".list-values li").addClass("is_mobile");
        $(".list-values__secondary").removeClass("hide");
    } else {
        $(".list-values li").hover(
            function () {
                $(this).find(".list-values__primary").addClass("hide");
                $(this).find(".list-values__secondary").removeClass("hide");
            },
            function () {
                $(this).find(".list-values__primary").removeClass("hide");
                $(this).find(".list-values__secondary").addClass("hide");
            }
        );
    }

    /**
     * Magnific Popup Video
     */
    $(".js-video").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        fixedContentPos: false,
        callbacks: {
            open: function () {
                jQuery("body").addClass("noscroll");
            },
            close: function () {
                jQuery("body").removeClass("noscroll");
            },
        },
    });

    $(".js-scroll-to-nav a").on("click", function (event) {
        event.preventDefault();

        animateViewportScroll(
            $($(this).attr("href")).offset().top -
                $(this).closest("section").outerHeight(),
            500
        );
    });


    /**
     * About Us > Timeline Scripts
     */
    let selected_year_index = 0;

    $("#timeline-year-menu").on("change", function () {
        let new_index = this.selectedIndex - 1;
        let new_image = timeline_array[new_index].image ? timeline_array[new_index].image : timeline_default_logo;

        $("#timeline-image").fadeOut(400, function () {
            $(this).attr('src', new_image).fadeIn(400);
        });
        $("#timeline-year-display").fadeOut(400, function () {
            $(this).html(timeline_array[new_index].year).fadeIn(400);
        });
        $("#timeline-description").fadeOut(400, function () {
            $(this).html(timeline_array[new_index].description).fadeIn(400);
        });

        selected_year_index = new_index;

        console.log(selected_year_index + "/" + timeline_array.length);
    });

    $(".timeline__content-buttons a").on("click", function (e) {
        e.preventDefault();

        if ($(this).hasClass("timeline__content-nav-next")) {
            selected_year_index =
                selected_year_index < timeline_array.length - 1
                    ? selected_year_index + 1
                    : 0;
        } else {
            selected_year_index =
                selected_year_index >= 1
                    ? selected_year_index - 1
                    : timeline_array.length - 1;
        }

        let new_image = timeline_array[selected_year_index].image ? timeline_array[selected_year_index].image : timeline_default_logo;

        $("#timeline-image").fadeOut(400, function () {
            $(this)
                .attr('src', new_image)
                .fadeIn(400);
        });
        $("#timeline-year-display").fadeOut(400, function () {
            $(this)
                .html(timeline_array[selected_year_index].year)
                .fadeIn(400);
        });
        $("#timeline-description").fadeOut(400, function () {
            $(this)
                .html(timeline_array[selected_year_index].description)
                .fadeIn(400);
        });
        $("#timeline-year-menu").val("");

        // console.log( selected_year_index + '/' + timeline_array.length );
    });

    var loadTimeline = function () {

        if ( $('.timeline__wrapper-all').length ) {
            $("#timeline-image").attr('src', timeline_array[0].image);
            $("#timeline-year-display").html(timeline_array[0].year);
            $("#timeline-description").html(timeline_array[0].description);
        }
    };

    loadTimeline();
});

$(window).on("load scroll", function () {
    const fixedNavWrapperOffset = $(".js-fixed-nav-wrapper").length
        ? $(".js-fixed-nav-wrapper").offset().top
        : 0;

    $(".js-fixed-nav").toggleClass(
        "is-fixed",
        $(window).scrollTop() >= fixedNavWrapperOffset
    );
});

function is_touch_enabled() {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}
